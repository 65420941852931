
  import Vue from 'vue'
  import UserList from '@/components/admin/UserList.vue'

  export default Vue.extend({
    name: 'AdminUsers',
    components: {
      UserList
    }
  })
